/**
 * Safely retrieves a deeply nested property from an object, with support for dot-notation paths.
 *
 * This helper function was introduced to enhance code readability and reduce errors
 * when accessing deeply nested properties in complex objects. By using this function,
 * we eliminate the need for repetitive `&&` checks, making the code more concise and maintainable.
 * It returns a specified default value if any part of the path is missing or undefined.
 *
 * This function is also flexible: it accepts the `path` as either an array of keys or a dot-notation
 * string (e.g., "a.b[0].c"). The latter will be automatically parsed, allowing for consistent and
 * readable syntax.
 *
 * Example usage:
 *   const value = getNestedProperty<MyType>(obj, "a.b[0].c.d", "default");
 *   // Returns "default" if any part of the path is missing or undefined.
 *
 * @param {T} obj - The object to search.
 * @param {string | string[]} path - The path to the property, either as a dot-notation string or an array of keys.
 * @param {any} [defaultValue=undefined] - The value to return if the property is not found.
 * @returns {any} - The value at the specified path or the default value if not found.
 */
export function getNestedProperty<T>(obj: T, path: string | string[], defaultValue = undefined) {
    const keys = Array.isArray(path)
        ? path
        : path.replace(/\[(\d+)\]/g, '.$1').split('.'); // Converts "a[0].b" to ["a", "0", "b"]
    return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue), obj);
}
